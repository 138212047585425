<!--
 * @Description: 订单签署方式选择
 * @Author: zhang zhen
 * @Date: 2024-09-05 14:59:10
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-05 17:49:46
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/reOrderSign.vue
-->
<template>
  <a-modal
    title="请选择上传订单协议方式"
    :width="600"
    okText="确认"
    :visible="visible"
    centered
    @ok="handleOk"
    @cancel="handleClose"
  >
    <a-radio-group v-model="jumpType">
      <a-radio :style="radioStyle" value="1"> 在线签署 </a-radio>
      <div class="desc">
        合作双方在线签署由平台提供的订单协议。 <a-button type="link" @click="handleOpenNewPage">《查看采购协议》</a-button>
      </div>
      <a-radio :style="radioStyle" value="2"> 线下签署 </a-radio>
      <div class="desc">双方线下签署纸质协议。完成签署后，直接上传协议文件。</div>
    </a-radio-group>
  </a-modal>
</template>

<script>
import { postAction, downGetFiles } from '@/api/manage'
export default {
  name: 'reOrderSign',
  data() {
    return {
      infoObj: {},
      visible: false,
      jumpType: '1',
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
    }
  },
  methods: {
    handleOpenNewPage() {
      downGetFiles('/order/agreement/download', {
        orderNo: this.infoObj.orderNo,
      }).then((res) => {
        let url = window.URL.createObjectURL(new Blob([res], { type: 'application/pdf' }))
        window.open(url)
        // window.URL.revokeObjectURL(url) // 释放掉blob对象
      })
    },
    handleOpen(item) {
      this.visible = true
      this.infoObj = { ...item }
    },
    handleOk() {
      postAction('/v1/contract/order/initiate', {
        orderNo: this.infoObj.orderNo,
        signType: this.jumpType,
      }).then((res) => {
        const { data, success, message } = res
        if (success) {
          this.$emit('ok')
          // 接口
          this.$emit('changeType', {
            type: this.jumpType,
            record: data || this.infoObj,
          })
          this.handleClose()
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleClose() {
      this.jumpType = '1'
      this.infoObj = {}
      this.visible = false
    },
  },
}
</script>

<style lang="less" scoped>
.desc {
  padding-left: 26px;
  // font-size: 16px;
  color: #8c8c8c;
  font-weight: 400;
  line-height: 24px;
  margin-top: 2px;
  margin-bottom: 34px;
  &:last-of-type {
    margin-bottom: 0;
  }
  .ant-btn-link {
    color: #316bc4;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
