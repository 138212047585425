<!--
 * @Description: 拖拽文件
 * @Author: zhang zhen
 * @Date: 2023-04-08 15:54:25
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-04-23 23:41:35
 * @FilePath: /page-sass/src/components/plugins/uploadDrag.vue
-->
<template>
  <a-upload-dragger
    name="file"
    :multiple="true"
    :action="uploadAction"
    :headers="headers"
    :data="{ dir: 'images' }"
    :before-upload="beforeUpload"
    :fileList="fileList"
    :disabled="disabled"
    :remove="removeOption"
    @change="handleChangeImage"
  >
  <!-- fileList.length >= number ||  -->
    <div class="uploadInfo">
      <img src="/new_upload.png" alt="" class="icon">
      <span style="color: #FF6026">{{ uploadText }}</span>
      <a-divider type="vertical" style="margin-top: 4px;background-color: #131212;" />
      <span style="color: #5E5E66;">拖拽到此区域</span>
    </div>
    <!-- <p class="ant-upload-drag-icon">
      <a-icon type="cloud-upload" />
    </p>
    <p class="ant-upload-text">
      将文件拖拽到此处
    </p>
    <p class="ant-upload-hint">
      或点击上传
    </p> -->
  </a-upload-dragger>
</template>
<script>
import Vue from 'vue'
// String 构造函数的原型对象的一个方法。
String.prototype.IsPicture = function() {
  //判断是否是图片 - strFilter必须是小写列举
  var strFilter = '.jpeg|.jpg|.png|.pic|'
  if (this.indexOf('.') > -1) {
    var p = this.lastIndexOf('.')
    //alert(p);
    //alert(this.length);
    var strPostfix = this.substring(p, this.length) + '|'
    strPostfix = strPostfix.toLowerCase()
    //alert(strPostfix);
    if (strFilter.indexOf(strPostfix) > -1) {
      //alert("True");
      return true
    }
  }
  //alert('False');
  return false
}
const uidGenerator = () => {
  return '-' + parseInt(Math.random() * 10000 + 1, 10)
}
export default {
  name: 'SuploadFile',
  props: {
    // 上传的其他信息
    defaultParams: {
      type: Object,
      default: function() {
        return {}
      }
    },
    // 上传类型
    uploadFileType: {
      type: String,
      required: false,
      default: 'image'
    },
    // 这个属性用于控制文件上传的业务路径
    bizPath: {
      type: String,
      required: false,
      default: 'temp'
    },
    uploadText: {
      type: String,
      required: false,
      default: '点击上传'
    },
    // 文件地址
    value: {
      type: [Array],
      required: false
    },
    number: {
      type: Number,
      required: false,
      default: 3
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  data() {
    return {
      uploadAction: `${process.env.VUE_APP_API_BASE_URL}/file/images/upload`,
      headers: {},
      uploadGoOn: true, // 上传错误的不展示
      fileList: [],
      previewVisible: false,
      imageUrl: '', // 查看当前文件的url
      imageName: '', // 查看当前文件的name
      newParams: {}
    }
  },
  created() {
    this.headers = { token: JSON.parse(localStorage.getItem('authInfo'))['Authorization'] }
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        // console.log(newVal, oldVal)
        this.initFileList(newVal)
      },
      immediate: true
    }
  },
  methods: {
    initFileList(list) {
      // console.log(list)
      if (!list || list.length == 0) {
        this.fileList = []
        return
      }
      let fileList = []
      for (var a = 0; a < list.length; a++) {
        const { url, srcFileName } = list[a]
        console.log(list[a])
        fileList.push({
          fileName: srcFileName,
          srcFileName,
          name: srcFileName,
          requestUrl: url,
          url: url,
          type: srcFileName.split('.')[1],
          uid: uidGenerator(),
          status: 'done',
          response: {}
        })
      }
      // console.log(fileList)
      this.fileList = fileList
    },
    setParams(record) {
      this.newParams = record
    },
    handleCancel() {
      this.previewVisible = false
    },
    // 查看
    handlePreview(file) {
      const { fileName, thumbUrl, url } = file
      let isPic = fileName.IsPicture()
      if (isPic || this.uploadFileType == 'video' || this.uploadFileType == 'audio') {
        this.imageName = file.srcFileName
        this.imageUrl = thumbUrl || url
        this.previewVisible = true
      } else if (this.uploadFileType == 'file') {
        // console.log(111)
        if (file.type == 'pdf') {
          window.open(file.url)
        } else {
          window.location.href = file.url
        }
      }
    },
    removeOption(file) {
      console.log('删除', file)
      const that = this
      that.$confirm({
        title: '提示',
        content: '确认删除当前文件',
        okText: '确认',
        cancelText: '取消',
        onOk() {
          file.status = 'removed'
          file.uid = uidGenerator()
          that.handleDelete(file)
        },
        onCancel() {}
      })
      return false
    },
    //处理下文件的回头显示
    beforeUpload(file) {
      const isLt2M = file.size / 1024 / 1024 <= 10;
      if (!isLt2M) {
        this.$message.error('单个大小不超过10M !');
      }
      return isLt2M;
    },
    handleChangeImage(info) {
      console.log('--文件列表改变--', info)
      const { file } = info
      if (file) {
        const { status, response, uid } = file
        if (status == 'done') {
          if (!response.success) {
            info.fileList = info.fileList.filter(i => i.uid != uid) // 过滤上传失败的文件
          }
        }
      }
      if (!info.file.status && this.uploadGoOn === false) {
        info.fileList.pop()
      }
      let fileList = info.fileList
      if (info.file.status === 'done') {
        if (this.number > 0) {
          fileList = fileList.slice(-this.number)
        }
        if (info.file.response.success) {
          fileList = fileList.map(res => {
            if (res.response && res.response.data) {
              const { url, filename, ext } = res.response.data
              res.url = url
              res.srcFileName = filename
              res.fileName = filename
              res.fileType = ext
            }
            return res
          })
          // console.log(fileList)
          // this.$message.success(`${info.file.name} 上传成功!`)
        } else {
          this.$message.error(
            `${info.file.response.success ? info.file.response.message : '文件上传失败，不支持当前格式'}!`
          )
        }
      } else if (info.file.status === 'error') {
        this.$message.warning({
          content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">{`${info.file.name} 上传失败.`}</span>,
          icon: (h) => <img src="./noPass.png" width="21" height="21" />
        })
      } else if (info.file.status === 'removed') {
        // this.handleDelete(info.file)
      }
      this.fileList = fileList.filter(i => i.status != 'error')
      if (info.file.status === 'done' || info.file.status === 'removed') {
        let newFileList = []
        for (let a = 0; a < fileList.length; a++) {
          if (fileList[a].status === 'done') {
            console.log(fileList[a])
            let fileObj = {
              srcFileName: fileList[a].srcFileName,
              url: fileList[a].url
            }
            newFileList.push(fileObj)
          } else {
            return
          }
        }
        this.$emit('change', newFileList)
      }
    },
    // 删除逻辑
    handleDelete(file) {
      let index = this.fileList.indexOf(file)
      let newFileList = this.fileList.slice()
      console.log(index, newFileList)
      newFileList.splice(index, 1)
      this.fileList = newFileList
      this.handleChangeImage({ file: file, fileList: this.fileList })
    },
    getBase64(img, callback) {
      const reader = new FileReader()
      reader.addEventListener('load', () => callback(reader.result))
      reader.readAsDataURL(img)
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  }
}
</script>

<style lang="less" scoped>
::v-deep .ant-upload.ant-upload-drag {
  background-color: #fff;
}
.uploadInfo {
  min-height: 88px;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .ant-upload {
  padding: 0!important;
}
.icon {
  width: 16px;
  margin-right: 4px;
}
</style>
