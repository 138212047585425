<!--
 * @Description: 订单子组件
 * @Author: zhang zhen
 * @Date: 2023-02-15 16:26:46
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-06-17 21:20:38
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/orderItem.vue
-->
<template>
  <div class="infoCardItem">
    <div class="top-info">
      <span>订单编号：{{ orderInfo.orderNo || '' }}</span>
      <span>提交时间：{{ orderInfo.createTime | formatDate }}</span>
    </div>
    <div class="info-box">
      <h3 class="orderName left-title">{{ orderInfo.orderTitle || '' }}</h3>

      <div class="orderCount orderNum">
        <span class="num">{{ orderInfo.products || 0 }}</span>
      </div>
      <div class="orderCount orderNum">
        <span class="num">{{ orderInfo.amount || 0 }}元</span>
      </div>
      <div class="orderCount status flex">
        状态：
        <a-tag color="#f50" v-if="orderInfo.status == 0">
          待签署
        </a-tag>
        <a-tag color="#f50" v-if="orderInfo.status == 1">
          已签署
        </a-tag>
        <a-tag color="#2db7f5" v-else-if="orderInfo.status == 2">
          预付款
        </a-tag>
        <a-tag color="#108ee9" v-else-if="orderInfo.status == 3">
          生产中
        </a-tag>
        <a-tag color="#108ee9" v-else-if="orderInfo.status == 4">
          已发货
        </a-tag>
        <a-tag color="#108ee9" v-else-if="orderInfo.status == 5">
          运输中
        </a-tag>
        <a-tag color="#108ee9" v-else-if="orderInfo.status == 6">
          已收货
        </a-tag>
        <a-tag color="#87d068" v-else-if="orderInfo.status == 7">
          已完结
        </a-tag>
        <a-tag color="#2db7f5" v-else-if="orderInfo.status == 9">
          已付款
        </a-tag>
        <a-tag color="orange" v-else-if="orderInfo.status == -1">
          已取消
        </a-tag>
      </div>
      <div class="btns">
        <a-button type="primary" @click="handleOPenContractUploader" v-if="orderInfo.status == 0">上传合同</a-button>
        <!-- 上传付款单的时候不能是供应商，且必须是已经签署了 -->
        <a-button type="primary" @click="handleOpenPayOrder" v-else-if="orderInfo.status == 1 && setUserInfo().tradeIdentity != 0">上传付款单</a-button>
        <!-- 上传尾款单 -->
        <a-button type="primary" @click="handleOpenPayedOrder" v-else-if="orderInfo.status == 6 && setUserInfo().tradeIdentity != 0">上传付款单</a-button>
        <a-button type="primary" @click="handleOpenEntryProduct" v-else-if="orderInfo.status == 2 && setUserInfo().tradeIdentity == 0">进入生产</a-button>
        <a-button type="primary" @click="handleOpenTransport" v-else-if="orderInfo.status == 3 && setUserInfo().tradeIdentity == 0">上传发货单</a-button>
        <a-button type="primary" @click="handleOpenUploadShipped" v-else-if="orderInfo.status == 4 && setUserInfo().tradeIdentity != 0">上传收货单</a-button>
        <a-button type="primary" v-if="orderInfo.status == 9" @click="handleJumpAppraise">评价</a-button>
        <a-button @click="handleSwitchTab">查看详情</a-button>
      </div>
    </div>
    <!-- 上传合同 -->
    <UploadContract ref="UploadContract" :orderNo="orderInfo.orderNo"/>
    <!-- 预付款单 -->
    <UploadPaymentInfo ref="UploadPaymentInfo" :orderNo="orderInfo.orderNo" />
    <!-- 发货单 -->
    <UploadTransportOrder ref="UploadTransportOrder" :orderNo="orderInfo.orderNo" />
    <!-- 收货单 -->
    <UploadShippedFile ref="UploadShippedFile" :orderNo="orderInfo.orderNo" />
    <!-- 已收货 - 上传付款单 -->
    <UploadPayedFile ref="UploadPayedFile" :orderNo="orderInfo.orderNo" />

    <!-- 进入生产 -->
    <a-modal
      title="温馨提示"
      :visible="visible"
      centered
      :confirm-loading="confirmLoading"
      @ok="handleEntryProduct"
      @cancel="handleCancelProduct"
    >
      <div class="info-card">
        <img src="/check-circle-filled.png" alt="">
        <span>当前订单已进入生产~</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UploadContract from './uploadContract.vue'
import UploadPaymentInfo from './uploadPaymentInfo.vue'
import UploadTransportOrder from './uploadTransportOrder.vue'
import UploadShippedFile from './uploadShippedFile.vue'
import UploadPayedFile from './UploadPayedFile.vue'
import { postAction } from '@/api/manage'
export default {
  name: 'orderItem',
  components: {
    UploadContract,
    UploadPaymentInfo,
    UploadTransportOrder,
    UploadShippedFile,
    UploadPayedFile
  },
  inject: ['handleReloadData'],
  props: {
    orderInfo: {
      type: Object,
      default: () => ({
        productStatus: 0,
        createTime: ''
      })
    }
  },
  data() {
    return {
      visible: false,
      confirmLoading: false
    }
  },
  methods: {
    ...mapGetters(['setUserInfo']),
    handleSwitchTab() {
      const { orderTitle, orderNo } = this.orderInfo
      this.$router.push(
        this.setUserInfo().tradeIdentity == 0 || this.setUserInfo().tradeIdentity == 1
          ? `/orderManagement/orderDetails?orderTitle=${orderTitle}&orderNo=${orderNo}`
          : `/orderManagement/orderDetailsForUser?orderTitle=${orderTitle}&orderNo=${orderNo}`
      )
    },
    handleJumpAppraise() {
      const { orderTitle, orderNo } = this.orderInfo
      this.$router.push(
        this.setUserInfo().tradeIdentity == 0 || this.setUserInfo().tradeIdentity == 1
          ? `/orderManagement/appraisePurchaser?orderNo=${orderNo}`
          : `/orderManagement/appraiseForUser?orderNo=${orderNo}`
      )
    },

    /* 开启合同上传 */
    handleOPenContractUploader() {
      // 开启抽屉
      this.$refs.UploadContract.handleOpen()
    },
    /* 打开上传付款单 */
    handleOpenPayOrder() {
      this.$refs.UploadPaymentInfo.handleShowModal()
    },
    /* 进入生产 */
    handleOpenEntryProduct() {
      this.visible = true;
      this.confirmLoading = false;
    },
    /* 进入生产的提示 */
    handleEntryProduct() {
      postAction('/order/producing', {
        orderNo: this.orderInfo.orderNo
      }).then(res => {
        const { success, message } = res
        if (success) {
          // this.$message.success(message)
          this.handleReloadData(1)
        } else {
          this.$message.warning(message)
        }
      })
    },
    /* 取消发货提示 */
    handleCancelProduct() {
      this.visible = false;
      this.confirmLoading = false;
    },
    /* 开启发货单 */
    handleOpenTransport() {
      this.$refs.UploadTransportOrder.handleEdit({})
    },
    /* 收货单上传 */
    handleOpenUploadShipped() {
      this.$refs.UploadShippedFile.handleOpen()
    },
    /* 上传预付款 */
    handleOpenPayedOrder() {
      this.$refs.UploadPayedFile.handleOpen()
    }
  }
}
</script>

<style lang="less" scoped>
.infoCardItem {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 15px;
  .left-title {
    width: 300px;
    text-align: left;
  }
  div.orderNum {
    width: 140px;
    text-align: center;
  }
  .status {
    width: 120px;
    text-align: center;
  }
  .top-info {
    margin-bottom: 8px;
    color: #a8aab2;
    font-size: 13px;
    font-weight: 500;
    span {
      margin-right: 90px;
    }
  }
  .info-box {
    background: #f7f7f7;
    padding: 15px;
    border-radius: 4px;
    .flexLayout();
    .orderCount {
      font-size: 13px;
      .label {
        color: #9f9b9b;
      }
      .num {
        color: #090b12;
      }
      &.flex {
        display: flex;
        align-items: center;
      }
    }
  }
  .btns {
    width: 230px;
    text-align: right;
    padding-left: 25px;
    border-left: 1px solid #e4e4e4;
    .ant-btn {
      & + .ant-btn {
        margin-left: 8px;
      }
    }
  }
}
.info-card {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  img {
    width: 21px;
    height: 21px;
  }
  span {
    margin-left: 10px;
  }
}
</style>
